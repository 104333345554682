













































import Vue from "vue";
import Component from "vue-class-component";
import store from "@/store/store";
import ApiButton from "@/vue/components/ApiButton.vue";
import ChangePasswordDialogue from "@/vue/components/dialogues/ChangePasswordDialogue.vue";
import { UserRole } from "@/model/Enums";
import { User } from "@/model/User";
import { ILookupItem } from '@/model/LookupItem';
import utils from "@/utilities/Utils";

@Component({
    components: { ApiButton, ChangePasswordDialogue }
})
export default class Profile extends Vue {

    //
    // -- lifecycle hooks
    //

    async mounted(): Promise<void> {
        if (store.state.signedInUser == null) {
            await store.dispatch("loadSignedInUser");
        }
        if (store.state.signedInUser !== null) {
            this.user = store.state.signedInUser;
        }
    }

    //
    // -- properties
    //

    private user = new User();

    //
    // -- methods
    //

    private get roleOptions(): Array<ILookupItem> {
        return utils.enumToLookups(UserRole);
    }

    private get roleText() {
        const role = this.roleOptions.filter(l => l.id === this.user?.role)[0]
        return role ? role.description : "";
    }

    private changePassword() {
        const dialogue: ChangePasswordDialogue = this.$refs.changePasswordDialogue as ChangePasswordDialogue;
        dialogue.changePassword(this.user);
    }

}
