






























import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Ref, Watch } from "vue-property-decorator";
import ApiButton from "@/vue/components/ApiButton.vue";
import { PasswordChangeRequest } from "@/model/PasswordChangeRequest";
import { IUser, User } from "@/model/User";
import utils from "@/utilities/Utils";
import apiClient from "@/utilities/ApiClient";
import { VForm } from "@/vForm";
import * as toastr from "toastr";

@Component({ components: { ApiButton } })

export default class ChangePasswordDialogue extends Vue {

    //
    // -- properties
    //
    
    @Ref("passwordForm") readonly passwordForm!: VForm;

    // If user is changing their _own_ password, they need to enter the old one
    @Prop({ default: true }) shouldShowExisting!: boolean;
    
    private showDialogue: boolean = false;
    private passwordChangeReq = new PasswordChangeRequest();
    private user: User = new User();

    //
    // -- validations
    //

    private passwordErrorMessages: Array<string> = [];      

    @Watch("passwordChangeReq.newPassword")
    private async validatePassword() {
        if (utils.isEmptyOrWhitespace(this.passwordChangeReq.newPassword)) {
            this.passwordErrorMessages = [];
            return;
        }

        const response: { isOkay: boolean; message: string } = await apiClient.get("/api/user/validatePassword?password=" + encodeURIComponent(this.passwordChangeReq.newPassword));
        if (response.isOkay) {
            this.passwordErrorMessages = [];
        }
        else if (!response.isOkay && this.passwordErrorMessages.indexOf(response.message) == -1) {
            this.passwordErrorMessages = [];
            this.passwordErrorMessages.push(response.message);
            return;
        }
    }

    //
    // -- methods
    //

   async changePassword(user: IUser): Promise<void> {
        this.resetDialogue();
        const response: IUser = await apiClient.get(`/api/user/load?id=${user.id}`);
        this.user.update(response);
        this.passwordChangeReq.userID = response.id;
        this.showDialogue = true;
    }

    private resetDialogue() {
        utils.resetObject(this.user);
        utils.resetObject(this.passwordChangeReq);
        this.passwordErrorMessages = [];
        this.passwordForm?.resetValidation();
    }

    private async savePassword() {
        await this.validatePassword();
        const isValid = this.passwordForm.validate() && this.passwordErrorMessages.length === 0;

        if (!isValid) {
            toastr.warning("Please fix highlighted issues", "Cannot Save")
            return;
        } 

       await apiClient.post("/api/user/changePassword", this.passwordChangeReq, "save-password"); 

        toastr.success("Changed Password");
        this.$emit("saved")
        this.resetDialogue();
        this.showDialogue = false;           
    }

}
